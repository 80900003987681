// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
    data() {
        return {
            passwordFieldType: 'password',
            passwordConfirmationFieldType: 'password',
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordConfirmationToggleIcon() {
            return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        togglePasswordConfirmationVisibility() {
            this.passwordConfirmationFieldType = this.passwordConfirmationFieldType === 'password' ? 'text' : 'password'
        },
    },
}

export const _ = null
