<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ getAppName() }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          class="d-none d-lg-flex align-items-center p-5"
          lg="8"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              :src="imgUrl"
              alt="Login V2"
              fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="4"
      >
        <b-col
            class="px-xl-2 mx-auto"
            lg="12"
            md="6"
            sm="8"
        >
          <b-card-title
              class="font-weight-bold mb-1"
              title-tag="h2"
          >
            {{ $i18n.t('Welcome to') + " " + getAppName() + "!" }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $i18n.t('register_page_message') }}
            <b-alert
                :show="isInvitationToken"
                class="mt-1"
                variant="primary"
            >
              <div class="alert-body">
                {{ $i18n.t('register_with_invitation', {team: teamName}) }}
              </div>
            </b-alert>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
            >

              <!-- name -->
              <b-form-group
                  :label="$i18n.t('Name')"
                  label-for="name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                >
                  <b-form-input
                      id="name"
                      v-model="userName"
                      :state="errors.length > 0 ? false:null"
                      name="name"
                      placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                  :label="$i18n.t('Email')"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="email"
                      v-model="userEmail"
                      :disabled="isInvitationToken"
                      :state="errors.length > 0 ? false:null"
                      name="email"
                      placeholder="example@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>{{ $i18n.t('Password') }}</label>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required|password"
                    vid="password"
                >
                  <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                  >
                    <b-form-input
                        id="password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        name="password"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--password Confirmation-->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>{{ $i18n.t('password_confirmation') }}</label>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="password_confirmation"
                    rules="required|confirmed:password"
                >
                  <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                  >
                    <b-form-input
                        id="password_confirmation"
                        v-model="password_confirmation"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordConfirmationFieldType"
                        class="form-control-merge"
                        name="password_confirmation"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordConfirmationToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordConfirmationVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  block
                  type="submit"
                  variant="primary"
                  @click="register"
              >
                {{ $i18n.t('register') }}
                <b-spinner
                    v-if="isLoading"
                    small
                />
              </b-button>
              <div class="mt-1">
                {{ $i18n.t('register_message') }}
                <b-link :to="{name:'login'}">
                  <small>
                    {{ $i18n.t('Login') }}
                  </small>
                </b-link>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {email, required} from '@validations'
import {BAlert} from 'bootstrap-vue'
import {togglePasswordVisibility} from '@/mixins/ui/forms'
import store from '@/store'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      userEmail: '',
      userName: '',
      password: '',
      password_confirmation: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
      isInvitationToken: false,
      invitationToken: null,
      teamName: '',
      repository: RepositoryFactory.create('authApp'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (this.$route.query.invitationToken) {
      this.invitationToken = this.$route.query.invitationToken
      this.isInvitationToken = true
      this.getInvitationInfo(this.invitationToken)
    }
  },
  methods: {
    register() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$refs.registerValidation.validate().then(success => {
          if (success) {
            const payload = {
              name: this.userName,
              email: this.userEmail,
              password: this.password,
              password_confirmation: this.password_confirmation,
            }
            if (this.isInvitationToken) {
              this.$set(payload, 'invitationToken', this.invitationToken)
            }
            this.repository.register(payload).then(response => {
              this.isLoading = false
              this.$router.replace('/login')
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$i18n.t('Success'),
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: response.data.message,
                      },
                    })
                  })
            }).catch(error => {
              this.isLoading = false
              if (error.data && error.data.errors) {
                this.$refs.registerValidation.setErrors(error.data.errors)
              }
            })
          }
        })
      }
    },
    getInvitationInfo(token) {
      RepositoryFactory.create('invitations').getInvitationInfo({
        token,
      }).then(response => {
        const res = response.data.data
        this.userEmail = res.email
        this.teamName = res.team
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
